@import url('https://fonts.googleapis.com/css?family=Montserrat:100,200,300,500,600,800');
@import url('https://fonts.googleapis.com/css?family=Roboto+Mono');

:root {
  --blue: #3180de;
  --grey: #8394a4;
}

*,
*:before,
*:after,
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  word-wrap: break-word;
}

body,
div,
button,
a,
h1,
h2,
h3,
h4,
h5,
.filter-list-title,
.primary,
.secondary,
#dropdown-item {
  font-family: 'Montserrat', sans-serif !important;
  font-weight: 300 !important;
}

.list-title {
  font-weight: 500 !important;
}

.ui.label,
.ui.labels .label {
  background-color: var(--grey) !important;
  border-color: var(--grey) !important;
  color: #fff !important;
}

#icon-hamburger {
  margin: 1em;
  cursor: pointer;
}

#icon-blue {
  color: var(--blue) !important;
}

.activeButtonPaging.ui.button {
  background-color: var(--grey) !important;
}

#top-menu {
  font-size: 1.1rem;
}

.masthead.segment {
  min-height: 700px;
  background-size: cover !important;
}

.intro-panel {
  background-image: url(../images/fiberpay_background.jpg) !important;
  background-repeat: no-repeat !important;
  background-position: center top !important;
  background-size: 100% auto !important;
  height: calc(100vh - 240px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.codeSegment {
  font-family: 'Roboto Mono', monospace;
  word-wrap: break-word;
}

.ui.vertical.menu .dropdown.item:not(.upward) .menu {
  top: 40px !important;
}

.ui.vertical.menu .dropdown.item .menu {
  left: 0 !important;
  width: 100%;
  border-radius: 0 !important;
}

/* =========================================================== */
.problemsSolved h1,
.paymentChannels h1,
.businessModel h1,
.fiberpaySecret h1,
.ourTeam h1 {
  text-transform: uppercase !important;
}

.problemsSolved,
.businessModel,
.ourTeam {
  background-color: #fff;
  color: var(--blue);
}

.problemsSolved *,
.businessModel *,
.ourTeam * {
  color: var(--blue) !important;
}

/* =========================================================== */

.paymentChannels,
.fiberpaySecret {
  background-image: url('../images/fiberpay_background.jpg');
  background-size: cover;
  color: #fff;
}

/* =========================================================== */

.teamSegment {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* =========================================================== */

.loginSegment {
  width: 22rem;
  margin: auto;
}

.borderOff {
  margin-top:0 !important;
  border: 0 !important;
  border-radius: 0 !important;
}

#filter-list-header {
  background: var(--blue);
  color: #fff;
  font-weight: normal;
}

.filter-list-title {
  font-weight: 500 !important;
}

/* Sidebar */
#panelMenu {
  background-color: #404a54;
}

.pusher {
  background: #f2f5f8 !important;
}

.ui.sidebar.visible ~ .pusher {
  width: calc(100% - 260px);
}

/* Tables */
#table {
  margin-top: 10px;
}

table.selectable tr {
  cursor: pointer !important;
}

#api-keys-tab td {
  height: 51px;
}

.boldCell {
  font-weight: 500 !important;
}

th:not(#pagination-th),
thead {
  color: white !important;
  background-color: var(--grey) !important;
}

/* Footer */
#footer {
  padding: 30px;
  background-color: var(--grey);
}

#footer * {
  color: #fff;
}

#footer h4 {
  font-weight: 500 !important;
}

.link {
  color: #4183c4;
}

.cashbill_payment_channel {
  border: 3px solid transparent;
  cursor: pointer;
  margin: 7px !important;
}

.cashbill_payment_channel:hover,
.cashbill_payment_channel--selected {
  border: 3px solid #0a94f1 !important;
}

/* Json formatter */
.__json-pretty__ {
  line-height: 1.3;
  color: rgb(0, 0, 0);
  background: #f2f5f8;
  overflow: auto;
  padding: 1em;
}

.__json-key__ {
  color: #5469d4;
}

.__json-value__ {
  color: #bb5504;
}

.__json-string__ {
  color: #09825d;
}

.__json-boolean__ {
  color: #067ab8;
}

.__json-pretty-error__ {
  line-height: 1.3;
  overflow: auto;
}

/* Media queries */
@media only screen and (min-width: 768px) {
  .filterBar {
    position: absolute !important;
  }

  #table {
    margin-top: 80px;
  }
}

.filterBar {
  position: static;
}

#services-segment {
  background-color: #067ab8;
}

#footer-button-group .ui.button {
  color: #000000;
}

#footer-button-group .ui.button.primary {
  color: white;
  pointer-events: none;
}

.welcome-provision-grid .row {
  padding-top: 0 !important;
}

.notification {
  margin-bottom: 0 !important;
  padding: 0 !important;
}

.notificationMessageError {
  margin: 0 !important;
  background-color: #df2b13 !important;
  color: white !important;
}

.notificationMessageWarning {
  margin: 0 !important;
  background-color: #FA8128 !important;
  color: white !important;
}

.notificationMessageInfo {
  margin: 0 !important;
  background-color: #0a94f1 !important;
  color: white !important;
}